import React from "react"
import styled from "styled-components"
import ContentDiv from "../components/contentDiv"
import Layout from "../components/layout"
import Text, { TextType } from "../components/text"

const HeaderDiv = styled.div`
  margin-top: 80px;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
`

const Impressum = () => (
  <Layout>
    <ContentDiv>
      <HeaderDiv>
        <Text type={TextType.Header}>Impressum</Text>
      </HeaderDiv>
      <Text type={TextType.Body}>
        <p>
          <b>Ansprechpartner:</b> Dietrich, Süß & Jaschke Steuerberater PartGmbB
          <br />
          <b>Telefon:</b> +49 (0)89 820 8597 0 <br />
          <b>Telefax:</b> +49 (0)89 820 8597 55 <br />
          <b>E-Mail:</b> info@dietrich-suess.de
        </p>
        <p>
          <b>Anschrift</b>
        </p>
        <p>
          Dietrich, Süß & Jaschke Steuerberater PartGmbB <br />
          Landsberger Strasse 408 <br />
          81241 München <br />
          Bayern, Deutschland
        </p>
        <p>
          <b>Steuernummer:</b> 144/242/20443, Finanzamt München Abteilung I{" "}
          <br />
          <b>UST ID Nr:</b> DE247637870
        </p>
        <p>
          Der Inhalt dieser Seite unterliegt dem Copyright von Dietrich, Süß &
          Jaschke PartGmbB © 2021. Alle Rechte vorbehalten.
        </p>
        <p>
          Vertretungsberechtigte Partner sind Diplom-Kaufmann Alexander Süß,
          Steuerberater <br />
          Michael Dietrich, Steuerberater <br />
          Jochen Jaschke B.A., Steuerberater <br />
          Die gesetzliche Berufsbezeichnung Steuerberater wurde in der
          Bundesrepublik Deutschland (Bundesland: Bayern) verliehen. <br />
        </p>
        <p>Zuständige Aufsichtsbehörde: Steuerberaterkammer München</p>
        <p>
          Die berufliche Tätigkeit ist abgesichert durch eine
          Vermögensschaden-Haftpflichtversicherung bei der ERGO Versicherung AG,
          40198 Düsseldorf. Diese gilt EU-weit in Zusammenhang mit den
          versicherten Risiken.
        </p>
        <p>
          Berufsrechtliche Regelungen***: <br />
          - Steuerberatungsgesetz (StBerG) <br />
          - Durchführungsverordnung zum Steuerberatungsgesetz (DVStB) <br />
          - Berufsordnung für Steuerberater (BOStB) <br />
          - Steuerberatervergütungsverordnung (StBVV) <br />
          Die Regelungen können bei der Bundessteuerberaterkammer unter
          https://www.bstbk.de/ (dort unter downloads/Berufsrecht) eingesehen
          werden***. <br />
          Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV: Alexander Süß
          (Anschrift wie oben)**** <br />
          Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen
          wir keine Haftung für die Inhalte externer Links. Für den Inhalt der
          verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
        <p>
          <b>Kontakt</b>
        </p>
        <p>
          Unser Datenschutz Beauftragter: Dominik Mikulovic <br />
          Kontakt: info@data-security.one <br />
        </p>
        <p>
          <b>1. Inhalt des Onlineangebotes</b>
          <br />
          Der Betreiber übernimmt keinerlei Gewähr für die Aktualität,
          Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
          Informationen. Haftungsansprüche gegen den Betreiber, welche sich auf
          Schäden materieller oder ideeller Art beziehen, die durch die Nutzung
          oder Nichtnutzung der dargebotenen Informationen bzw. durch die
          Nutzung fehlerhafter und unvollständiger Informationen verursacht
          wurden, sind grundsätzlich ausgeschlossen, sofern seitens des
          Betreibers kein nachweislich vorsätzliches oder grob fahrlässiges
          Verschulden vorliegt. Alle Angebote sind freibleibend und
          unverbindlich. Der Betreiber behält es sich ausdrücklich vor, Teile
          der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
          verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
          oder endgültig einzustellen.
        </p>
        <p>
          <b>2. Verweise und Links</b>
          <br />
          Bei direkten oder indirekten Verweisen auf fremde Internetseiten
          ("Links"), die außerhalb des Verantwortungsbereiches des Betreibers
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
          Kraft treten, in dem der Betreiber von den Inhalten Kenntnis hat und
          es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern. Der Betreiber erklärt hiermit
          ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
          Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
          aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft
          der gelinkten/verknüpften Seiten hat der Betreiber keinerlei Einfluss.
          Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten
          aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert
          wurden. Diese Feststellung gilt für alle innerhalb des eigenen
          Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge
          in vom Betreiber eingerichteten Gästebüchern, Diskussionsforen und
          Mailinglisten, für illegale, fehlerhafte oder unvollständige Inhalte
          und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung
          solcherart dargebotener Informationen entstehen
          https://dietrichsuess.de/ und für alle Inhalte der Seiten, zu denen
          diese Links führen. Die Betreiber der jeweiligen Seiten handeln in
          Eigenverantwortung und unterliegen dem jeweiligen Landesrecht. Ferner
          haften wir nicht für direkte oder indirekte Schäden (inkl. entgangener
          Gewinne), die auf Informationen zurückgeführt werden können, die auf
          diesen externen Internetseiten stehen.
        </p>
        <p>
          <b>3. Urheber- und Kennzeichenrecht</b>
          <br />
          Der Betreiber ist bestrebt, in allen Publikationen die Urheberrechte
          der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
          beachten, von ihm selbst erstellte Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb
          des Internetangebotes genannten und ggf. durch Dritte geschützten
          Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen
          des jeweils gültigen Kennzeichenrechts und den Besitzrechten der
          jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen
          Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht
          durch Rechte Dritter geschützt sind! Das Copyright für
          veröffentlichte, vom Betreiber selbst erstellte Objekte bleibt allein
          beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher
          Grafiken, Tondokumente, Videosequenzen und Texte in anderen
          elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
          Zustimmung des Autors nicht gestattet.
        </p>
        <p>
          <b>4. Datenschutz</b>
          <br />
          Die Nutzung unserer Webseite ist ohne Angabe personenbezogener Daten
          möglich (Es werden keine Cookies verwendet). Personenbezogene Daten
          werden nicht erhoben. Wir weisen darauf hin, dass die Datenübertragung
          im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
          Dritte ist nicht möglich.
        </p>
        <p>
          <b>5. Auskunft, Löschung, Sperrung</b>
          <br />
          Du hast jederzeit das Recht auf unentgeltliche Auskunft über Deine
          gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
          und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
          zum Thema Deiner personenbezogenen Daten kannst Du Dich jederzeit über
          die im Impressum angegebene Email Adresse an uns wenden.
        </p>
      </Text>
    </ContentDiv>
  </Layout>
)

export default Impressum
